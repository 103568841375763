/* eslint-disable */
import { Col, FormCheck, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import PaymentHistory from "./PaymentHistory";
import { useUser } from "../UserContext";

const MyAccount = () => {
	const { userProfile } = useUser();

	return (
		<div>
			<Helmet>
				<title>{"My EQQO Account"}</title>
			</Helmet>

			<h2>My Account</h2>
			<Row className="py-4">
				{/* Terrible markup follows, but for visual demonstration purposes... */}
				<Col md={6}>
					<h4>Name</h4>
					<p>{ `${userProfile?.firstName} ${userProfile?.lastName}` }</p>
				</Col>
				<Col md={6}>
					<h4>Email</h4>
					<p>{userProfile?.email}</p>
				</Col>
				<Col md={6}>
					<h4>Newsletter</h4>
					<FormCheck id="newsletter-opt-in" label="Subscribe to our awesome newsletter" name="newsletter" type="switch" />
				</Col>
			</Row>
			<Row>
				<PaymentHistory />
			</Row>
		</div>
	);
}

export default MyAccount;
