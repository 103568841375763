/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import { Col, Container, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import QuestionnaireForm from "./QuestionnaireForm";
import SignUpStepIndicator from "./SignUpStepIndicator";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useUser } from "../UserContext";

const Questionnaire = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	// This step of sign-up requires auth
	useEffect(() => {
		if (!user) {
			navigate("/sign-in");
		}
	}, []);
	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Create Your EQQO Account"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img
					alt="LOGO" className="logo" height="152" src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white flow">
					<SignUpStepIndicator current={2} />
					<h1>Respondent Questionnaire</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</p>
					<QuestionnaireForm />
				</Container>
			</Col>
		</Row>
	);
};
export default Questionnaire;
