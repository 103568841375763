/* eslint-disable max-len, no-console, capitalized-comments */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable */
import * as yup from "yup";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import Helmet from "react-helmet";
import { useAuth } from "../services/eqqo.auth.service";
// import { useUser } from "../UserContext";

const SignIn = () => {
	const { login, user } = useAuth();
	// const { respondentData } = useUser();
	const navigate = useNavigate();
	const [signInError, setSignInError] = useState(null);
	const initialValues = {
		email: "",
		password: ""
	};

	const validationSchema = yup.object({
		email: yup.string().email("Invalid email. Use format alex@example.com").
			required("Email address is required"),
		password: yup.string().
			required("Password is required").
			min(8, "Password must be at least 8 characters")
	});

	const handleFormSubmit = useCallback((values, actions) => {
		setSignInError(null);
		login({
			email: values.email,
			password: values.password
		}).
			catch((error) => {
				setSignInError(error.message);
			}).
			finally(() => {
				actions.setSubmitting(false);
			});
	});

	useEffect(() => {
		if (user) {
			navigate("/sign-in/confirm");
		}
	}, [user]);

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Sign In to Your EQQO Account"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img
					alt="LOGO"
					className="logo"
					height="152"
					src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white">
					<h1 className="mb-4">Sign In to Your EQQO Account</h1>
					<p className="mb-4">
						Welcome back
					</p>
					{signInError &&
						<div className="alert alert-danger my-3" dangerouslySetInnerHTML={{ __html: `${signInError}` }} /> }
					<Formik
						initialValues={initialValues}
						onSubmit={handleFormSubmit}
						validationSchema={validationSchema}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values
						}) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Label>Email Address</Form.Label>
								<Form.Control
									isInvalid={Boolean(errors.email)}
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									placeholder="alex@example.com"
									required
									type="email"
									value={values.email}
								/>
								<Form.Control.Feedback type="invalid">
									{touched.email && errors.email ? <p className="mb-0">{errors.email}</p> : null}
								</Form.Control.Feedback>
								<Form.Label className="mt-3">Password</Form.Label>
								<Form.Control
									isInvalid={Boolean(errors.password)}
									name="password"
									onChange={handleChange}
									placeholder="Password"
									required
									type="password"
									value={values.password}
								/>
								<Form.Control.Feedback type="invalid">
									<p className="mb-0">{errors.password}</p>
								</Form.Control.Feedback>
								<Button className="btn-gradient mt-4 text-uppercase w-100" type="submit">
									{isSubmitting ? <Spinner animation="border" size="sm" /> : "Sign In"}
								</Button>
							</Form>)}
					</Formik>
					<NavLink className="d-block mt-4" to="/sign-in/forgot-password">Forgot Password</NavLink>
					<p className="labeled-divider">OR</p>
					<Button className="w-100" variant="outline text-uppercase btn-outline-eqqo-purple">
						Sign In with Google
					</Button>
					<p className="mt-5 mb-2">Don't have an account?</p>
					<NavLink to="/sign-up/account">Sign Up</NavLink>
				</Container>
			</Col>
		</Row>
	);
};

export default SignIn;
