/* eslint-disable */
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useAuth } from "../services/eqqo.auth.service";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
	const { logout } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		logout().then(() => {
			navigate("/");
		});
	}, []);

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"You are being logged out..."}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img
					alt="LOGO"
					className="logo"
					height="152"
					src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white flow">
					<h1 className="py-4">You are being logged out...</h1>
				</Container>
			</Col>
		</Row>
	);
};

export default Logout;
