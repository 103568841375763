import { graphql } from "../../services/wp.api.service";

export const query = `
	mutation WriteWeqResponses($input: UpdateWeqProfileInput!) {
		updateWeqProfile(input: $input) {
			weqProfile {
				weqProfileAcfFields {
					profilestatus
				}
				profileResponses {
					response
					stamp
					statement_id
				}
			}
		}
	}
`;

const responsesMutation = (useMutation, options) => useMutation(
	"saveResponses",
	(input) => graphql({
		query,
		variables: {
			input
		}
	}).
		then(({ data }) => {
			if (data.errors) {
				throw new Error(data.errors[0].message);
			}

			return { data };
		}),
	{
		...options
	}
);

export default responsesMutation;
