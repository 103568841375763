/* eslint-disable max-len, react/jsx-sort-props, react/jsx-newline, react/jsx-max-props-per-line, multiline-ternary, react/no-unknown-property, react/jsx-tag-spacing, react/jsx-indent, indent */
const ImagePlaceholder = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M63.3333 10H16.6667C12.9848 10 10 12.9848 10 16.6667V63.3333C10 67.0152 12.9848 70 16.6667 70H63.3333C67.0152 70 70 67.0152 70 63.3333V16.6667C70 12.9848 67.0152 10 63.3333 10Z" stroke="#F4F5F5" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M28.3334 33.3334C31.0948 33.3334 33.3334 31.0948 33.3334 28.3334C33.3334 25.5719 31.0948 23.3334 28.3334 23.3334C25.5719 23.3334 23.3334 25.5719 23.3334 28.3334C23.3334 31.0948 25.5719 33.3334 28.3334 33.3334Z" stroke="#F4F5F5" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M70 50L53.3333 33.3334L16.6666 70" stroke="#F4F5F5" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ImagePlaceholder;
