/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import CheckmarkIcon from "../../icons/CheckmarkIcon";
import Helmet from "react-helmet";

const CreateAccount = () => {
	const { state } = useLocation();

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"EQQO Account Created"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img alt="LOGO" className="logo" height="152" src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white flow">
					<CheckmarkIcon />
					<h1>Account Created</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</p>
					<NavLink className="btn btn-gradient" to="/sign-up/questionnaire">Continue</NavLink>
				</Container>
			</Col>
		</Row>
	);
};

export default CreateAccount;
