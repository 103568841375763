/* eslint-disable react/no-unescaped-entities, react/jsx-no-bind, prefer-named-capture-group, no-console */
import * as yup from "yup";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useCallback, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

import purchaseSingleCredit from "./purchaseSingleCreditMutation";
import { useAuth } from "../services/eqqo.auth.service";
import { useNotifications } from "../../components/NotificationsContext";

const initialValues = {
	billingCity: "",
	billingCountry: "",
	billingState: "",
	billingStreet: "",
	billingZip: "",
	cardCVC: "",
	cardExp: "",
	cardNumber: "",
	name: ""
};

const validationSchema = yup.object({
	billingCity: yup.string().required(),
	billingCountry: yup.string().required(),
	billingState: yup.string().
		max(2).
		required(),
	billingStreet: yup.string().required(),
	billingZip: yup.string().required(),
	cardCVC: yup.string().
		min(3).
		max(4).
		required(),
	cardExp: yup.string().
		matches(/^(0[1-9]|1[0-2])\/\d{2}$/gu, "Invalid expiration date").
		required(),
	cardNumber: yup.string().max(16).
		required(),
	name: yup.string().required()
});

const PaymentForm = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	const { addNotification } = useNotifications();
	const { mutateAsync } = purchaseSingleCredit(useMutation, user.databaseId);

	const [loading, setLoading] = useState(false);

	const handleSubmit = useCallback(async () => {
		setLoading(true);

		try {
			const res = await mutateAsync("some-token");

			if (res.errors) {
				throw new Error(res.errors[0].message);
			}

			navigate("/sign-up/pay/success");
		} catch (error) {
			addNotification({
				message: error.message,
				type: "danger"
			});
		}
		setLoading(false);
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{(formik) => (
				<Form className="flow" noValidate onSubmit={formik.handleSubmit}>
					<h2>Card Information</h2>
					<Row>
						<Col>
							<Form.Label>Name on Card</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.name)}
								name={"name"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"name"} type="invalid">
								{formik.errors.name ? <p>{formik.errors.name}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Card Number</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.cardNumber)}
								name={"cardNumber"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"cardNumber"} type="invalid">
								{formik.errors.cardNumber ? <p>{formik.errors.cardNumber}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>MM / YY</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.cardExp)}
								name={"cardExp"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"cardExp"} type="invalid">
								{formik.errors.cardExp ? <p>{formik.errors.cardExp}</p> : null}
							</Form.Control.Feedback>
						</Col>
						<Col>
							<Form.Label>CVC</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.cardCVC)}
								name={"cardCVC"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"cardCVC"} type="invalid">
								{formik.errors.cardCVC ? <p>{formik.errors.cardCVC}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<hr />
					<h2>Billing Address</h2>
					<Row>
						<Col>
							<Form.Label>Country</Form.Label>
							<Form.Select
								isInvalid={Boolean(formik.errors.billingCountry)}
								name={"billingCountry"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							>
								<option>Choose One</option>
								<option>United States</option>
								<option>Canada</option>
							</Form.Select>
							<Form.Control.Feedback name={"billingCountry"} type="invalid">
								{formik.errors.billingCountry ? <p>{formik.errors.billingCountry}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>Street Address</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingStreet)}
								name={"billingStreet"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingStreet"} type="invalid">
								{formik.errors.billingStreet ? <p>{formik.errors.billingStreet}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Label>City</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingCity)}
								name={"billingCity"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingCity"} type="invalid">
								{formik.errors.billingCity ? <p>{formik.errors.billingCity}</p> : null}
							</Form.Control.Feedback>
						</Col>
						<Col>
							<Form.Label>State</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingState)}
								name={"billingState"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingState"} type="invalid">
								{formik.errors.billingState ? <p>{formik.errors.billingState}</p> : null}
							</Form.Control.Feedback>
						</Col>
						<Col>
							<Form.Label>Zip</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.billingZip)}
								name={"billingZip"}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name={"billingZip"} type="invalid">
								{formik.errors.billingZip ? <p>{formik.errors.billingZip}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Button className="w-100" disabled={loading} type="submit" variant="primary">{
						loading
							? <Spinner animation="border" />
							: "Make Payment"
					}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default PaymentForm;
