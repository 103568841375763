/* eslint-disable max-len */

import { Col, Container, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import PaymentForm from "./PaymentForm";
import SignUpStepIndicator from "./SignUpStepIndicator";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useUser } from "../UserContext";

const Payment = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	// This step of sign-up requires auth
	useEffect(() => {
		if (!user) {
			navigate("/sign-in");
		}
	}, []);
	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Account Payment"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img
					alt="LOGO" className="logo" height="152" src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white">
					<SignUpStepIndicator current={3} />
					<h1 className="my-4">Account Payment</h1>
					<p className="my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</p>
					<h2 className="mt-5 mb-3">Subtotal</h2>
					<p className="mt-3 mb-5 total-due">$195.00</p>
					<hr />
					<PaymentForm />
				</Container>
			</Col>
		</Row>
	);
};

export default Payment;
