/* eslint-disable max-len, no-console, no-unused-vars, capitalized-comments */
/* eslint-disable react/no-unescaped-entities */
import * as yup from "yup";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { submitSignInCodeFn, useAuth } from "../services/eqqo.auth.service";
import { Formik } from "formik";
import Helmet from "react-helmet";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const SignInConfirmation = () => {
	const { user } = useAuth();
	const navigate = useNavigate();

	const initialValues = {
		signInCode: ""
	};

	const validationSchema = yup.object({
		signInCode: yup.string().
			matches(/^[0-9]+$/u, "Must be only digits").
			min(6, "Must be exactly 6 digits").
			max(6, "Must be exactly 6 digits").
			required("Please enter the code.")
	});

	const handleFormSubmit = useCallback(async (values) => {
		const input = {
			id: user.databaseId,
			signInCode: values.signInCode
		};

		if (process.env.NODE_ENV !== "production") {
			navigate("/sign-up/questionnaire");
		}

		try {
			await submitSignInCodeFn({ signInCode: values.signInCode });
			if (user.weqProfiles === null) {
				navigate("/sign-up/questionnaire");
			} else {
				navigate("/profile/assessment/instructions");
			}
		} catch (error) {
			console.log(error);
		}
	});

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Sign In to Your EQQO Account"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<img
					alt="LOGO"
					className="logo"
					height="152"
					src="/eqqo_logo.png"
					width="472"
				/>
				<Container className="bg-white flow">
					<h1>Sign In to Your EQQO Account</h1>
					<p>
						Please enter the code we sent to your email.
					</p>
					<Formik
						initialValues={initialValues}
						onSubmit={handleFormSubmit}
						validationSchema={validationSchema}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values
						}) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Label>Sign In Code</Form.Label>
								<Form.Control
									isInvalid={Boolean(errors.signInCode)}
									name="signInCode"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									type="text"
									value={values.signInCode}
								/>
								<Form.Control.Feedback type="invalid">
									{touched.signInCode && errors.signInCode ? <p>{errors.signInCode}</p> : null}
								</Form.Control.Feedback>
								<Button className="btn-gradient mt-4 w-100" type="submit">
									{isSubmitting ? <Spinner animation="border" size="sm" /> : "Sign In"}
								</Button>
							</Form>)}
					</Formik>
				</Container>
			</Col>
		</Row>
	);
};

export default SignInConfirmation;
