/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */

import { ArrowLeftCircleIcon, DocumentIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Container, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ProfileNav = () => {
	return (
		<Container className="profile__nav">
			<img alt="LOGO" className="logo" height="152" src="/eqqo_logo.png"
				width="472"
			/>
			<Nav as="nav" className="flex-column fs-18 pt-4" defaultActiveKey="/profile/assessment/instructions">
				<Nav.Item>
					<NavLink className="nav-link text-primary" to="/profile/assessment/instructions">
						<DocumentIcon />
						Instructions
					</NavLink>
				</Nav.Item>
				<Nav.Item>
					<NavLink className="nav-link text-primary" eventKey="/profile/help" to="/profile/help">
						<QuestionMarkCircleIcon />
						Help
					</NavLink>
				</Nav.Item>
				<Nav.Item className="mt-auto">
					<NavLink className="nav-link text-primary" eventKey="/logout" to="/logout">
						<ArrowLeftCircleIcon height={24} width={24} />
						Logout
					</NavLink>
				</Nav.Item>
			</Nav>
		</Container>
	);
};

export default ProfileNav;
