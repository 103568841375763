/* eslint-disable max-statements */
/* eslint-disable camelcase */
export const transformAnswers = (answers, respondent_id) => Object.entries(answers).map(([key, value]) => ({
	respondent_id,
	response: parseInt(value, 10),
	statement_id: parseInt(key, 10)
}));

export const transformResponsesToAnswers = (responses, questions) => {

	const qIds = questions.reduce((prev, cur) => {
		prev[cur.id || cur.statement_id] = true;
		return prev;
	}, {});

	return responses.reduce((prev, cur) => {
		if (qIds[cur.statement_id]) {
			prev[cur.statement_id] = cur.response;
		}
		return prev;
	}, {});
};

export const getProfileProgress = (responses, scenarios) => {
	const status = {};
	let nextScenario = null,
		profileCompleted = true;

	for (const idx in scenarios) {
		if (scenarios[idx]) {
			const cur = scenarios[idx];
			const questionsArr = Object.values(cur.questions);
			const answers = transformResponsesToAnswers(responses, questionsArr);

			let completed = false;
			if (Object.values(answers).length >= questionsArr.length) {
				completed = true;
			} else {
				if (!nextScenario) {
					nextScenario = idx;
				}
				profileCompleted = false;
			}
			status[idx] = completed;
		}
	}

	return {
		nextScenario,
		profileCompleted,
		status
	};
};

/**
 * Activity Sort Function
 *
 * Handles the sort of activity dates so that the date render
 * can be independent of the sort.
 *
 * @param {*} rowA The first row we're comparing.
 * @param {*} rowB The second row we're comparing.
 *
 * @returns int
 */
export const respondentActivitySortFn = (rowA, rowB) => {
	const aDate = rowA.original?.lastActivity || rowA.original.profileDate;
	const bDate = rowB.original?.lastActivity || rowB.original.profileDate;
	if (aDate > bDate) {
		return 1;
	}
	return -1;
};
