import { graphql } from "../../services/wp.api.service";

const query = `
mutation PurchaseSingleCredit($paymentToken: String = "") {
  purchaseSingleCredit(input: {paymentToken: $paymentToken}) {
    order {
      date
      id
    }
    clientMutationId
  }
}
`;

const purchaseSingleCredit = (useMutation, id, options) => useMutation(
	["purchaseSingleCredit", id],
	(paymentToken) => graphql({
		query,
		variables: {
			input: {
				paymentToken
			}
		}
	}),
	{
		mutationKey: `purchaseSingleCredit_${id}`,
		...options
	}
);

export default purchaseSingleCredit;
