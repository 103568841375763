/* eslint-disable multiline-ternary */
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ACFSelectField = ({
	defaultValue,
	field,
	isInvalid,
	name,
	onChange
}) => {

	const choiceLabels = Object.values(field.choices);
	const choiceValues = Object.keys(field.choices);
	const hasValues = Boolean(choiceValues[0]);

	return (
		<Form.Select
			defaultValue={defaultValue}
			isInvalid={isInvalid}
			name={name}
			onChange={onChange}
			placeholder={field.placeholder || field.label}
			required={Boolean(field.required)}
		>
			<option>{field.placeholder || ""}</option>
			{choiceLabels.map((choice, idx) => (
				<option
					key={choice}
					value={hasValues ? choiceValues[idx] : choice}
				>
					{choice}
				</option>))}
		</Form.Select>
	);
};

ACFSelectField.propTypes = {
	defaultValue: PropTypes.string,
	field: PropTypes.shape({
		choices: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.shape({})]).isRequired,
		label: PropTypes.string.isRequired,
		name: PropTypes.string,
		placeholder: PropTypes.string,
		required: PropTypes.number
	}).isRequired,
	isInvalid: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

ACFSelectField.defaultProps = {
	defaultValue: null,
	onChange: null
};

export default ACFSelectField;
