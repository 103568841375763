import { graphql } from "../../services/wp.api.service";

const query = `
	mutation userProfileMutation($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				firstName
				lastName
			}
		}
	}
`;

const saveUserProfile = (useMutation, id, options) => useMutation(
	["saveUserProfile", id],
	(input) => graphql({
		query,
		variables: {
			input: {
				id,
				...input
			},
			useErrorBoundary: true
		}
	}),
	{
		mutationKey: `saveUserProfile_${id}`,
		...options
	}
);

export default saveUserProfile;
