/* eslint-disable */
import { Button, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { Element, scroller } from "react-scroll";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import AssessmentData from "../../liaStatements/statements_en.json";
import AssessmentQuestion from "./AssessmentQuestion";
import EqqoVideo from "./EqqoVideo";
import responsesMutation from "./responsesMutation";
import { difference } from "lodash";
import { getProfileProgress, transformAnswers, transformResponsesToAnswers } from "../../helpers/respondents";
import { useMutation } from "react-query";
import { useTimer } from "react-timer-hook";
import { useUser } from "../UserContext";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const Assessment = () => {
	const { id } = useParams();
	const { user, respondent } = useUser();
	const navigate = useNavigate();
	// Navigation vars
	const isFinalPage = useMemo(() => parseInt(id, 10) === Object.keys(AssessmentData.scenarios).length, [id]);
	const forwardUrl = useMemo(() => isFinalPage ? `/report/landing` : `/profile/assessment/${ Number(id) + 1 }`, [id]);
	// handle submitting reponses to the backend
	const {
		isLoading,
		mutate
	} = responsesMutation(useMutation, {
		onError: (error) => {
			console.log(error);
		},
		onSuccess: ({ data }) => {
			// update our local cache of the responses
			const {
				profileResponses,
				weqProfileAcfFields: { profilestatus }
			} = data.data.updateWeqProfile.weqProfile;
			const newRespondent = {
				...respondent,
				weqProfile: {
					...respondent?.weqProfile,
					profileResponses,
					profileStatus: profilestatus
				},
				...getProfileProgress(profileResponses, AssessmentData.scenarios)
			};
			// repondent exists in UserContext but doesn't utilize React State like previous app
			// uncertain if it needs to, or should, but this is where we would do it
			// setRespondent(newRespondent);
			// addNotification({
			// 	message: "Responses submitted successfully.",
			// 	type: "success"
			// });
			navigate(forwardUrl);
		}
	});
	// Assessment question loading and handling
	const scenarioData = useMemo(() => AssessmentData.scenarios[id], [id]);
	const responseLabels = AssessmentData.responseLabels;
	const questionArray = Object.entries(scenarioData.questions).map((question) => (
		{
			id: question[1].statement_id,
			response: null,
			text: question[1].statement
		}
	));
	// state vars to hold user inputs
	const [answers, setAnswers] = useState(respondent?.weqProfile.profileResponses
		? transformResponsesToAnswers(respondent?.weqProfile.profileResponses, questionArray)
		: {});
	const [activeQuestion, setActiveQuestion] = useState(0);
	const updateQuestions = (value, { target }) => {
		const questionid = parseInt(target.name.split("-").pop(), 10);
		const newAnswers = {
			...answers,
			[questionid]: parseInt(value, 10)
		};
		setAnswers(newAnswers);
		setNextActiveQuestion(newAnswers);
	};
	const setNextActiveQuestion = (newAnswers) => {
		if (Object.values(newAnswers).length < questionArray.length) {
			let idx = 0;
			while (newAnswers[questionArray[idx]?.id]) {
				// eslint-disable-next-line no-plusplus
				idx++;
			}
			setActiveQuestion(idx);
		} else {
			setActiveQuestion(null);
		}
	};
	const handleSubmit = useCallback(() => {
		const answeredQuestions = Object.values(answers).length;
		const totalQuestions = questionArray.length;

		if (answeredQuestions === totalQuestions) {
			const mutationData = {
				id: user.weqProfileId,
				profileResponses: transformAnswers(answers, user.weqProfileId)
			};
			mutate(mutationData);
		} else {
			const answeredIds = Object.keys(answers).map((key) => parseInt(key, 10));
			// eslint-disable-next-line camelcase
			const questionIds = Object.values(scenarioData.questions).map(({ statement_id }) => parseInt(statement_id, 10));
			const unansweredIds = difference(questionIds, answeredIds).sort();
			const [firstUnanswered] = unansweredIds;
			// Scroll to first unanswered question.
			scroller.scrollTo(`question-${firstUnanswered}`, {
				offset: -240
			});
		}
	});
	
	// state variables for user progress through this scenario
	const [readScenario, setReadScenario] = useState(false);
	const [videoFinished, setVideoFinished] = useState(false); // track if video is finished, i.e. has been watched
	const onProgress = useCallback((progress) => {
		if (progress.played >= 0.99) {
			setVideoFinished(true);
		}
	}, []);
	const [canAnswer, setCanAnswer] = useState(false); // will be used to control if questions can be answered or not (must watch video first)
	const minTime = 10000; // minimum time that needs to elapse before user can answer questions, helps ensure they watch the video
	const [timeoutMinTime, setTimeoutMinTime] = useState(false);
	useTimer({
		autoStart: true,
		expiryTimestamp: new Date().getTime() + minTime,
		onExpire: () => {
			setTimeoutMinTime(true);
		}
	});

	useEffect(() => {
		if (videoFinished && timeoutMinTime && !canAnswer) {
			setCanAnswer(true); // enable answering questions
		}
	}, [videoFinished, timeoutMinTime]);

	return (
		<div className="assessment-view">
			<Row className="border-bottom pb-4">
				<h2 className="fs-24 mb-4">1. About the Scenario</h2>
				<Col md={6}>
					<h3 className="fs-18 text-eqqo-dark-gray">Your Role</h3>
					<p className="fs-18 text-eqqo-dark-gray">{scenarioData.scenario.roles}</p>
				</Col>
				<Col>
					<h3 className="fs-18 text-eqqo-dark-gray">The Situation</h3>
					<p className="fs-18 text-eqqo-dark-gray">{scenarioData.scenario.situation}</p>
				</Col>
				<div className="mt-5">
					<Button 
						className="btn-gradient w-100"
						onClick={() => setReadScenario(true)}
					>
						Continue <ArrowRightIcon height={20} width={20} />
					</Button>
				</div>
			</Row>
			<Row className="border-bottom my-5">
				<h2 className="fs-18 text-eqqo-dark-gray">2. Video</h2>
				<div className="pb-4 video" data-video-id={scenarioData.videoId}>
					<EqqoVideo
						className="d-block w-100 mw-100 rounded-2 overflow-hidden"
						config={{
							wistia: {
								options: {
									playbackRateControl: false,
									playbar: process.env.REACT_APP_SHOW_SCROLL_BAR || false,
									playerColor: "000000",
									plugins: {
										"captions-v1": {
											onByDefault: true
										}
									},
									preload: true,
									videoFoam: true
								}
							}
						}}
						onProgress={onProgress}
						style={{ aspectRatio: "16 / 9" }}
						url={`https://fast.wistia.net/embed/iframe/${scenarioData.videoId}`}
					/>
				</div>
				<div className="mt-5">
					<Button 
						className="btn-gradient w-100"
						onClick={() => setVideoFinished(true)}
					>
						I Have Watched the Video - Rate My Experience <ArrowRightIcon height={20} width={20} />
					</Button>
				</div>
			</Row>
			<Row>
				<h3>Questions</h3>
				<Stack gap={4}>
					{questionArray.map((ques, idx) => (
						<Element key={ques.id} name={"question-" + ques.id}>
							<AssessmentQuestion
								active={idx === activeQuestion}
								answer={answers[ques.id]}
								disabled={!canAnswer}
								key={ques.id}
								question={ques}
								responseLabels={responseLabels}
								updateQuestions={updateQuestions}
							/>
						</Element>
					))}
				</Stack>
				<div className="d-flex justify-content-between my-5">
					<Button
						className="btn-gradient w-100"
						disabled={isLoading}
						onClick={handleSubmit}
						variant="success"
					>
						{
							isLoading
								? <Spinner animation="border" size="sm" />
								: isFinalPage
									? "Submit My Responses"
									: "Next"
						}
						<ArrowRightIcon height={20} width={20} />
					</Button>
				</div>
			</Row>
		</div>
	);
};

export default Assessment;
