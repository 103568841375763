/* eslint-disable no-unused-vars */
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

import ACFCheckboxes from "../components/ACForm/ACFCheckboxes";
import ACFSelectField from "../components/ACForm/ACFSelectField";
import ACFTextField from "../components/ACForm/ACFTextField";

import { useFormikContext } from "formik";

const EqqoFormField = ({
	defaultValue,
	field,
	isInvalid,
	labelClasses,
	name,
	onBlur,
	onChange
}) => {
	const { errors } = useFormikContext();

	const renderField = (fieldSettings) => {
		switch (fieldSettings.type) {
		case "select":
			return (
				<Form.Group controlId={name}>
					<Form.Label className={labelClasses}>{field.label}</Form.Label>
					<ACFSelectField
						defaultValue={defaultValue}
						field={fieldSettings}
						isInvalid={isInvalid}
						name={name}
						onBlur={onBlur}
						onChange={onChange}
					/>
					{!!field.instructions && <Form.Text className="d-block">{field.instructions}</Form.Text>}
					<Form.Control.Feedback name={name} type="invalid">
						{errors[name] ? <p>{errors[name]}</p> : null}
					</Form.Control.Feedback>
				</Form.Group>
			);
		case "checkbox":
			return (
				<Form.Group controlId={name}>
					<ACFCheckboxes
						defaultValue={defaultValue}
						field={field}
						isInvalid={isInvalid}
						labelClasses={labelClasses}
						name={name}
						onBlur={onBlur}
						onChange={onChange}
					/>
					<Form.Control.Feedback name={name} type="invalid">
						{errors[name] ? <p>{errors[name]}</p> : null}
					</Form.Control.Feedback>
				</Form.Group>);
		default:
			return (
				<Form.Group controlId={name}>
					<Form.Label className={labelClasses}>{field.label}</Form.Label>
					<ACFTextField
						defaultValue={defaultValue}
						field={fieldSettings}
						isInvalid={isInvalid}
						name={name}
						onBlur={onBlur}
						onChange={onChange}
					/>
					{!!field.instructions && <Form.Text className="d-block">{field.instructions}</Form.Text>}
					<Form.Control.Feedback name={name} type="invalid">
						{errors[name] ? <p>{errors[name]}</p> : null}
					</Form.Control.Feedback>
				</Form.Group>
			);
		}
	};
	return (
		renderField(field)
	);
};

EqqoFormField.propTypes = {
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	field: PropTypes.shape({
		instructions: PropTypes.string,
		label: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		required: PropTypes.number,
		type: PropTypes.string,
		wrapper: PropTypes.shape({
			width: PropTypes.string
		}).isRequired
	}).isRequired,
	isInvalid: PropTypes.bool,
	labelClasses: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired
};

EqqoFormField.defaultProps = {
	className: "mb-4",
	defaultValue: null,
	isInvalid: false,
	labelClasses: "",
	name: null,
	onBlur: null
};

export default EqqoFormField;
