/* eslint-disable no-magic-numbers */
import { graphql } from "../../services/wp.api.service";

const query = `
	query userProfileQuery($id: ID!) {
		user(id: $id, idType: DATABASE_ID) {
			email
			firstName
			lastName
			userProfile {
				age
				zip
				workStatus
				industry
				position
				education
				state
				race
				gender
				country
			}
		}
	}
`;

const transformRespondent = (user) => user;

const profileQuery = (useQuery, id, options) => {
	const variables = {
		id: parseInt(id, 10),
		useErrorBoundary: true
	};

	return useQuery(
		["userProfile", id],
		() => graphql({
			query,
			variables
		}).
			then((res) => transformRespondent(res.data.data.user)),
		{
			staleTime: 10 * 60 * 1000,
			...options
		}
	);
};

export default profileQuery;
