/* eslint-disable max-len, react/jsx-sort-props, react/jsx-max-props-per-line */
import PropTypes from "prop-types";

const CheckmarkIcon = ({ background }) => {
	const fill = background;
	return (
		<svg className="checkmark-icon" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="80" height="80" rx="40" fill={fill} />
			<path d="M33 41L37 45L47 35" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

CheckmarkIcon.propTypes = {
	background: PropTypes.string
};

CheckmarkIcon.defaultProps = {
	background: "var(--bs-primary, #656F7C)"
};

export default CheckmarkIcon;
