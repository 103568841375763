import { Col, Container, Row } from "react-bootstrap";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import ProfileNav from "./ProfileNav";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { useUser } from "../UserContext";

const Profile = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	// Profile routes require auth
	useEffect(() => {
		if (!user) {
			navigate("/sign-in");
		}
	}, []);
	return (
		<Row as="main" className="profile | flex-nowrap bg-eqqo-blue-white">
			<Helmet>
				<title>{"EQQO Profile"}</title>
			</Helmet>

			<Col className="profile__sidebar bg-white" lg={3}>
				<ProfileNav />
			</Col>
			<Col className="profile__content" lg={9}>
				<Container className="align-items-center d-flex flex-nowrap justify-content-between px-0 py-4">
					<h1 className="mb-0">EQQO Profile</h1>
					<NavLink className="d-flex align-items-center" to="/profile/account">
						<UserCircleIcon className="me-1" height={20} width={20} /> My Account
					</NavLink>
				</Container>
				<Container className="bg-white rounded-2">
					<Outlet />
				</Container>
			</Col>
		</Row>
	);
};

export default Profile;
