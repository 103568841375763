/* eslint-disable max-len */
import * as yup from "yup";
import { Button, Form, Spinner } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import { requestPasswordResetFn } from "../services/eqqo.auth.service";
import { useMutation } from "react-query";

const schema = yup.object().shape({
	email: yup.string().email().
		required()
});

const ForgotPasswordForm = () => {
	const [displaySuccess, setDisplaySuccess] = useState(false);
	const {
		isLoading,
		mutate
	} = requestPasswordResetFn(useMutation);
	const onSubmit = useCallback(({ email }) => {
		mutate(email, {
			onSuccess: () => {
				setDisplaySuccess(true);
			}
		});
	}, [mutate]);

	const SuccessMessage = () => useMemo(() => (
		<div className="alert alert-success my-4">
			<h3 className="mb-4">
				Check your E-mail.
			</h3>

			<p className="ms-auto me-auto" style={{ maxWidth: "400px" }}>
				If your email address is registered with our system, you will receive an email to reset your password. If you do not receive this email, please <a href="https://learninginaction.com/contact/">Contact Us</a>.
			</p>
		</div>), []);

	return displaySuccess
		? <SuccessMessage />
		: (
			<Formik
				initialValues={{
					email: ""
				}}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					touched,
					values
				}) => (
					<Form noValidate onSubmit={handleSubmit}>
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							isInvalid={Boolean(errors.email)}
							name="email"
							onBlur={handleBlur}
							onChange={handleChange}
							placeholder="alex@example.com"
							required
							type="email"
							value={values.email}
						/>
						<Form.Control.Feedback type="invalid">
							{touched.email && errors.email ? <p>{errors.email}</p> : null}
						</Form.Control.Feedback>

						<Button className="btn-gradient mt-4 w-100" type="submit">
							{isLoading ? <Spinner animation="border" size="sm" /> : "Reset Password"}
						</Button>
					</Form>
				)}
			</Formik>
		);
};

export default ForgotPasswordForm;
