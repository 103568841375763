/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/no-unescaped-entities */

/* eslint-disable capitalized-comments */
import "../App.scss";
import "./Eqqo.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./services/eqqo.auth.service";
import { Container } from "react-bootstrap";
import { ReactQueryDevtools } from "react-query/devtools";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";

import Loader from "../components/Loader";
import { NotifcationsContextProvider } from "../components/NotificationsContext";

import AccountCreated from "./sign-up/AccountCreated";
import Assessment from "./profile/Assessment";
import ForgotPassword from "./sign-up/ForgotPassword";
import Help from "./profile/Help";
import Instructions from "./profile/Instructions";
import Landing from "./sign-up/Landing";
import Logout from "./sign-up/Logout";
import MyAccount from "./profile/MyAccount";
import Payment from "./sign-up/Payment";
import PaymentSuccess from "./sign-up/PaymentSuccess";
import Profile from "./profile/Profile";
import Questionnaire from "./sign-up/Questionnaire";
import Report from "./Report";
import ReportLanding from "./ReportLanding";
import SignIn from "./sign-up/SignIn";
import SignInConfirmation from "./sign-up/SignInConfirmation";
import SignUp from "./sign-up/SignUp";
import { UserContextProvider } from "./UserContext";

/*
 * These dynamic imports establish code splitting.
 * Webpack will generate one bundle/chunk for each of the
 * staff, practitioner, and respondent sections of the app.
 */
// const StaffRoutes = React.lazy(() => import("./pages/staff/StaffRoutes" /* webpackChunkName: "staff" */));

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 1000 * 60 * 15
		}
	}
});

const useLocalStorage = false;
if (useLocalStorage) {
	const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });
	persistQueryClient({
		buster: "Stuff",
		persistor: localStoragePersistor,
		queryClient
	});
}

const App = () => (
	<QueryClientProvider client={queryClient}>
		<AuthProvider>
			<NotifcationsContextProvider>
				<UserContextProvider>
					<Container className="App" fluid>
						<Suspense
							fallback={<Loader />}
						>
							<Routes>
								<Route element={<Landing />} path="*" />
								<Route element={<SignUp />} path="/sign-up" />
								<Route element={<AccountCreated />} path="/sign-up/success" />
								<Route element={<Questionnaire />} path="/sign-up/questionnaire" />
								<Route element={<Payment />} path="/sign-up/pay" />
								<Route element={<PaymentSuccess />} path="/sign-up/pay/success" />
								<Route element={<SignIn />} path="/sign-in" />
								<Route element={<SignInConfirmation />} path="/sign-in/confirm" />
								<Route element={<ForgotPassword />} path="/sign-in/forgot-password" />
								<Route element={<Logout />} path="/logout" />
								<Route element={<Profile />} path="/profile">
									<Route element={<Help />} path="help" />
									<Route element={<MyAccount />} path="account" />
									<Route element={<Instructions />} path="assessment/instructions" />
									<Route element={<Assessment />} path="assessment/:id" />
								</Route>
								<Route element={<ReportLanding />} path="/report/landing" />
								<Route element={<Report />} path="/report" />
							</Routes>
						</Suspense>
					</Container>
				</UserContextProvider>
			</NotifcationsContextProvider>
		</AuthProvider>
		<ReactQueryDevtools initialIsOpen={false} />
	</QueryClientProvider>
);

export default App;
