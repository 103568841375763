/* eslint-disable max-len */

import { Col, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import ImagePlaceholder from "../../icons/ImagePlaceholder";
import { NavLink } from "react-router-dom";

const Landing = () => (
	<Row as="main" className="sign-up cover">
		<Helmet>
			<title>
				{"Sign Up for EQQO"}
			</title>
		</Helmet>

		<Col className="sign-up-content flow" md={7} xs={12}>
			<img
				alt="LOGO" className="logo" height="152" src="/eqqo_logo.png"
				width="472"
			/>
			<h1>Sign Up For EQQO</h1>
			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna.Lorem ipsum dolor sit amet, consectetur adipiscing elit ut</p>
			<NavLink className="btn btn-gradient text-center" to="/sign-up">Get Started</NavLink>
			<NavLink className="btn btn-secondary text-center" to="/sign-in" >SIGN IN</NavLink>
		</Col>
		<Col className="sign-up-decoration" md={5} xs={12}>
			<ImagePlaceholder />
		</Col>
	</Row>
);

export default Landing;
