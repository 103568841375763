import PropTypes from "prop-types";
import { Stack } from "react-bootstrap";

const SignUpStepIndicator = ({ current }) => (
	<Stack className="sign-up-steps justify-content-center" direction="horizontal" gap={3}>
		<div className="p-2" data-complete={current > 1} data-current={current === 1} data-number="1">Account</div>
		<div className="p-2" data-complete={current > 2} data-current={current === 2} data-number="2">Questions</div>
		<div className="p-2" data-current={current === 3} data-number="3">Payment</div>
	</Stack>
);

SignUpStepIndicator.propTypes = {
	current: PropTypes.number.isRequired
};

export default SignUpStepIndicator;
