import { graphql } from "./wp.api.service";

const query = `
mutation MyMutation($input: UpdateWeqProfileInput!) {
	updateWeqProfile(input: $input) {
	  weqProfile {
		databaseId
	  }
	}
}
`;

export const updateWeqProfileMutation = (useMutation, params) => useMutation(
	["updateWeqProfile"],
	(input) => graphql({
		query,
		variables: { input }
	}),
	{
		useErrorBoundaries: true,
		...params
	}
);
