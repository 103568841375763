/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { useMemo } from "react";

const AssessmentQuestionScale = ({ disabled, id, responseLabels, updateQuestions, value }) => {
	const fieldName = useMemo(() => `options-${id}`, [id]);

	return (
		<ToggleButtonGroup
			className="d-flex"
			name={fieldName}
			onChange={updateQuestions}
			questionid={id}
			type="radio"
			value={value}
		>
			{
				responseLabels.map((responseLabel) => (
					<ToggleButton disabled={disabled} id={`tbg-btn-${id}-${responseLabel.rating}`} key={Number(responseLabel.rating)} value={Number(responseLabel.rating)} variant="primary">
						{responseLabel.rating}
					</ToggleButton>
				))
			}
		</ToggleButtonGroup>
	);
};

AssessmentQuestionScale.propTypes = {
	disabled: PropTypes.bool.isRequired,
	id: PropTypes.number.isRequired,
	responseLabels: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateQuestions: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired
};

export default AssessmentQuestionScale;
