import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ACFTextField = ({
	defaultValue,
	field,
	isInvalid,
	name,
	onChange
}) => (
	<Form.Control
		defaultValue={defaultValue}
		isInvalid={isInvalid}
		name={name}
		onChange={onChange}
		required={Boolean(field.required)}
	/>
);

ACFTextField.propTypes = {
	defaultValue: PropTypes.string,
	field: PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
		placeholder: PropTypes.string,
		required: PropTypes.number
	}).isRequired,
	isInvalid: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

ACFTextField.defaultProps = {
	defaultValue: null,
	onChange: null
};

export default ACFTextField;
